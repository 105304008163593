'use strict';

angular.module('informaApp')
    .directive('infToggle', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'directives/inf-toggle/template.ptl.html',
                scope: {
                    rightText: "@",
                    leftText: "@",
                    model: "="
                },
                link: function (scope, element, attrs, ngModel) {
                }
            };
        }
    ]);